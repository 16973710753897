import React, { useState, useEffect, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import languages from '../../../__fixtures__/languages';
import { Form, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import cn from 'classnames';
import DataTable from 'react-data-table-component';

import ListStrInput from '../../common/ListStrInput';
import Icon from '../../UI/Icon';
import CustomRegReqList from './CustomRegReqList';
import { defaultAppInfoRegistrationRequirements } from '../helpers/fillAppInfoRegistrationRequirements';

const AppCreateForm = ({
    appInfo = {},
    setAppInfo = () => {},
    onSubmit = () => {},
    language = 'en',
    setLanguage = () => {},
    editorData = '',
    setEditorData = () => {},
}) => {
    const [app, setApp] = useState(appInfo);

    // Custom merchant registration requirements
    const [customRegReq, setCustomRegReq] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = _.cloneDeep(app);

        // update data here if needed
        if (!_.isEmpty(customRegReq)) {
            data.settings.merchant_registration_requirements = [
                ...data.settings.merchant_registration_requirements,
                ...customRegReq,
            ];
        }

        onSubmit(data);
    };

    const handleChangeAppField = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setApp((prev) => {
            const newAppInfo = _.cloneDeep(prev);
            _.set(newAppInfo, name, value);
            return newAppInfo;
        });
    };

    useEffect(() => {
        console.log('app changed: ', app);
    }, [app]);

    const onRequirementsClick = (data) => {
        const regInfo = app.settings.merchant_registration_requirements;
        const newInfo = regInfo.map((e) => {
            if (e.id === data.id) {
                return {
                    id: data.id,
                    required: data.required,
                    name: data.name,
                    mandatory: data.mandatory,
                    type: data.type,
                };
            } else {
                return e;
            }
        });

        const newApp = _.cloneDeep(app);
        newApp.settings.merchant_registration_requirements = newInfo;

        setApp(newApp);
    };

    const columns = useMemo(() => [
        {
            name: 'Is used',
            grow: 1,
            cell: (row) => {
                const iconName = row.required
                    ? 'bi-check2-square'
                    : 'bi-square';
                return (
                    <div
                        onClick={() => {
                            const new_row = Object.assign({}, row, {
                                required: !row.required,
                                mandatory: false,
                            });
                            return onRequirementsClick(new_row);
                        }}
                        data-tip
                        data-for="selectTip"
                    >
                        <Icon iconName={`btn ${iconName}`} />
                    </div>
                );
            },
        },
        {
            name: 'Information',
            grow: 3,
            cell: (e) => {
                return e.name;
            },
        },
        {
            name: 'Is mandatory',
            grow: 1,
            cell: (row) => {
                const iconName = row.mandatory
                    ? 'bi-check2-square'
                    : 'bi-square';
                return (
                    <div
                        onClick={() => {
                            const new_row = Object.assign({}, row, {
                                required: true,
                                mandatory: !row.mandatory,
                            });
                            return onRequirementsClick(new_row);
                        }}
                        data-tip
                        data-for="selectTip"
                    >
                        <Icon iconName={`btn ${iconName}`} />
                    </div>
                );
            },
        },
    ]);

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Check
                            type="switch"
                            id="active"
                            name="active"
                            label="Active"
                            checked={!!app.active}
                            onChange={(e) =>
                                handleChangeAppField({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>Main settings</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                type="text"
                                value={app.name}
                                onChange={handleChangeAppField}
                                placeholder="Application name"
                                required
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="classname">
                            <Form.Label>Package (class)</Form.Label>
                            <Form.Control
                                name="classname"
                                type="text"
                                value={app.classname}
                                onChange={handleChangeAppField}
                                placeholder="honkio"
                                required
                            />
                            <Form.Text className="text-muted">
                                Unique string, which will be used to access API
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="email">
                            <Form.Label>Admin email</Form.Label>
                            <Form.Control
                                name="email"
                                type="email"
                                value={app?.email || ''}
                                onChange={handleChangeAppField}
                                placeholder="admin@genii-solutions.com"
                                required
                            />
                            <Form.Text className="text-muted">
                                Used to send an invitation to the admin merchant
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>OAuth</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.str_password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                name="settings.str_password"
                                type="text"
                                value={app?.settings?.str_password || ''}
                                onChange={handleChangeAppField}
                                placeholder=""
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.str_oauth_id">
                            <Form.Label>OAuth ID</Form.Label>
                            <Form.Control
                                name="settings.str_oauth_id"
                                type="text"
                                value={app?.settings?.str_oauth_id || ''}
                                onChange={handleChangeAppField}
                                placeholder=""
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.str_oauth_secret">
                            <Form.Label>OAuth Secret</Form.Label>
                            <Form.Control
                                name="settings.str_oauth_secret"
                                type="text"
                                value={app?.settings?.str_oauth_secret || ''}
                                onChange={handleChangeAppField}
                                placeholder=""
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <ListStrInput
                            label="List of oauth allowed URI"
                            info="List of URI allowed to be used in user auth process"
                            name="settings.list_oauth_allowed_uri"
                            value={app?.settings?.list_oauth_allowed_uri}
                            onChange={(val) =>
                                handleChangeAppField({
                                    target: {
                                        name: 'settings.list_oauth_allowed_uri',
                                        value: val,
                                    },
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <ListStrInput
                            label="List of oauth redirect URI"
                            info="List of URI allowed to be used to redirect user after authentication"
                            name="settings.list_oauth_redirect_uri"
                            value={app?.settings?.list_oauth_redirect_uri}
                            onChange={(val) =>
                                handleChangeAppField({
                                    target: {
                                        name: 'settings.list_oauth_redirect_uri',
                                        value: val,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>Android settings</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.dict_min_version.android">
                            <Form.Label>Android min version</Form.Label>
                            <Form.Control
                                name="settings.dict_min_version.android"
                                type="text"
                                value={
                                    app?.settings?.dict_min_version?.android ||
                                    ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="2.0.1"
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.android_push_api_key">
                            <Form.Label>Android push API key</Form.Label>
                            <Form.Control
                                name="settings.android_push_api_key"
                                type="text"
                                value={
                                    app?.settings?.android_push_api_key || ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="hash"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>iOS settings</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Check
                            type="switch"
                            id="settings.bool_ios_sandbox"
                            name="settings.bool_ios_sandbox"
                            label="Sandbox mode"
                            checked={!!app?.settings?.bool_ios_sandbox}
                            onChange={(e) =>
                                handleChangeAppField({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.dict_min_version.ios">
                            <Form.Label>iOS min version</Form.Label>
                            <Form.Control
                                name="settings.dict_min_version.ios"
                                type="text"
                                value={
                                    app?.settings?.dict_min_version?.ios || ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="17.3"
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.ios_api_key">
                            <Form.Label>iOS API key</Form.Label>
                            <Form.Control
                                name="settings.ios_api_key"
                                type="text"
                                value={app?.settings?.ios_api_key || ''}
                                onChange={handleChangeAppField}
                                placeholder="hash"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.ios_api_credentials">
                            <Form.Label>
                                iOS Credentials (Key id, Team Id, Package)
                            </Form.Label>
                            <Form.Control
                                name="settings.ios_api_credentials"
                                type="text"
                                value={app?.settings?.ios_api_credentials || ''}
                                onChange={handleChangeAppField}
                                placeholder="text"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>Merchant registration requirements</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <DataTable
                            dense
                            striped
                            noDataComponent="no data"
                            highlightOnHover
                            data={
                                app?.settings
                                    ?.merchant_registration_requirements ||
                                defaultAppInfoRegistrationRequirements
                            }
                            columns={columns}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CustomRegReqList
                            customRegReq={customRegReq}
                            setCustomRegReq={setCustomRegReq}
                        />
                    </Col>
                </Row>

                <hr />

                <h4>Terms of use (TOU)</h4>

                <Row>
                    <Col xs={12}>
                        <Form.Check
                            type="switch"
                            id="bool_plugin_tou"
                            name="settings.bool_plugin_tou"
                            label="Enable plugin"
                            checked={!!app?.settings?.bool_plugin_tou}
                            onChange={(e) =>
                                handleChangeAppField({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Form.Group controlId="settings.float_tou_version">
                            <Form.Label>Version</Form.Label>
                            <Form.Control
                                name="settings.float_tou_version"
                                type="number"
                                min={0.0}
                                step={0.01}
                                value={app?.settings?.float_tou_version || ''}
                                onChange={handleChangeAppField}
                                placeholder="0.0"
                                required
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group controlId="settings.str_tou_url">
                            <Form.Label>TOU URL</Form.Label>
                            <Form.Control
                                name="settings.str_tou_url"
                                type="text"
                                value={app?.settings?.str_tou_url || ''}
                                onChange={handleChangeAppField}
                                placeholder="https://domain/tou"
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Form.Control
                            as="select"
                            name="language"
                            value={language}
                            onChange={(e) => {
                                console.log(
                                    'change language: value ==',
                                    e.target.value
                                );
                                setLanguage(e.target.value);
                            }}
                            className={cn('custom-select', 'crsr', 'mb-3')}
                        >
                            <option value="">Language</option>
                            {languages.map((opt, i) => (
                                <option
                                    key={`logo-option-${i}-${opt?.value}`}
                                    value={opt?.value}
                                >
                                    {opt?.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>

                    <Col xs={12} className="mb-3">
                        <CKEditor
                            editor={ClassicEditor}
                            data={editorData}
                            onChange={(_, editor) => {
                                setEditorData(editor.getData());
                            }}
                        />
                    </Col>
                </Row>

                <Button
                    type="submit"
                    variant="primary"
                    className={cn('float-right')}
                >
                    Save changes
                </Button>
            </Form>
        </div>
    );
};

export default AppCreateForm;
